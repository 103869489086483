import React, { useContext, useState } from 'react';

import { Button, Upload, Form, Popover, Row, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import LoadingSpin from '../../utils/LoadingSpin';
import { notificationError, requestError } from '../../../helpers/notification';
import { CalendarsTabContext } from '../../../contexts/calendar/CalendarsTabContext';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import AuthService from '../../../services/auth.service';

const CalendarExclusionsImport = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_calendars' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const { selectedCalendar, setSelectedCalendar } = useContext(CalendarsTabContext);
    const { dayDefinitions, refreshCalendars } = useContext(CalendarMenuContext);
    const [selectedDayDefinition, setSelectedDayDefinition] = useState(null);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const { isAdmin } = AuthService.getCurrentUser();

    const importExclusions = (xmlHttpRequest) => {
        if (!isAdmin) {
            return;
        }
        setLoading(true);
        CalendarService.importExclusions(selectedCalendar, selectedDayDefinition, xmlHttpRequest.file)
            .then((updatedCalendar) => {
                if (updatedCalendar.warnings?.length > 0) {
                    notificationError(t('calendar_exception_import_error'), updatedCalendar.warnings.join('\n'), { duration: 0 });
                    setLoading(false);
                }
                    setSelectedCalendar(updatedCalendar.calendar);
                    refreshCalendars().finally(() => {
                        setLoading(false);
                        setPopoverVisible(false);
                    });
                
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendar_exception_import_error'));
            });
    };

    const updateDayDefinition = (value) => {
        setSelectedDayDefinition(dayDefinitions.filter((dayDefinition) => dayDefinition.id === value).first());
    };

    const content = (
        <div className="min-h-52 w-80 flex items-center">
            {loading ? (
                <div className="w-full">
                    <LoadingSpin />
                </div>
            ) : (
                <Form layout="vertical" className="w-full">
                    <Row>
                        <Form.Item
                            name="day_definition"
                            label={generalTranslation('type')}
                            rules={[{ required: true, message: generalTranslation('required_field') }]}
                            className="w-full"
                        >
                            <Select
                                placeholder={t('select_day_placeholder')}
                                style={{ boxShadow: 'inset 2px 3px 3px rgba(130, 130, 130, 0.15)' }}
                                className="w-full"
                                onChange={updateDayDefinition}
                                showSearch
                                dropdownClassName="rounded"
                                filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {dayDefinitions.map((dayDefinition) => (
                                    <Select.Option
                                        key={`calendar-${selectedCalendar.id}-day-definition-${dayDefinition.id}-select-option-import-exception`}
                                        value={dayDefinition.id}
                                        title={dayDefinition.name}
                                    >
                                        {dayDefinition.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Row>
                    <Upload.Dragger customRequest={importExclusions} accept=".ics" className="w-full">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="text-sm px-12">{t('ics_file_import_message_info')}</p>
                    </Upload.Dragger>
                </Form>
            )}
        </div>
    );

    if (!isAdmin) {
        return <></>;
    }

    return (
        <Popover
            placement="bottom"
            title={t('import_exception_title')}
            content={content}
            trigger="click"
            open={popoverVisible}
            onOpenChange={(value) => setPopoverVisible(value)}
        >
            <Button className="w-full mb-2" onClick={() => setPopoverVisible(!popoverVisible)}>
                {generalTranslation('import')}
            </Button>
        </Popover>
    );
};
export default CalendarExclusionsImport;
